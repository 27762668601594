import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "generate-csp"
    }}>{`Generate CSP`}</h1>
    <p>{`Our CSP is served through a Cloudflare headers policy. Updating that policy is fairly simple but generating the right CSP can be tricky. This file helps you do just that.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <ol>
      <li parentName="ol">{`Update `}<inlineCode parentName="li">{`./scripts/generate-csp/index.js`}</inlineCode>{` with your new entries`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`node scripts/generate-csp`}</inlineCode>{` and copy the output`}</li>
      <li parentName="ol">{`Update `}<inlineCode parentName="li">{`kualibuild/identity`}</inlineCode>{` in `}<inlineCode parentName="li">{`src/shared/secure/index.js`}</inlineCode>{` around line 61`}</li>
      <li parentName="ol">{`Update the production-security-headers custom policy in AWS at Cloudfront > Policies > Response headers`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      